import { Box, Heading, Text} from '@chakra-ui/react'
import React from 'react'
//import Motto from '../components/motto'
import MeinSEO from '../components/seo'
import { motion } from 'framer-motion'

import {Link} from 'gatsby'



// const TextDe = () => (
//     <Box p="4" shadow="md" maxW="400">
//         <Heading my="8">
//             Impressum
//         </Heading>
//         <Text px="0" fontSize={["lg","xl"]}>Datenschutzbeauftragter:<br/>Prof. Susanne Enderwitz</Text>
//          <Link to="/datenschutz"> &rarr; Datenschutzbestimmungen</Link>

//         <Text variant="MeA" mt="6">Projektakteure:
        
//         </Text>
//         <List>
//             <ListItem>Museum Simmern<br/></ListItem>
//             <ListItem>Dr. Christoph Pies, Carolin Mann<br/></ListItem>
//             <ListItem>Kulturnetz Oberes Mitterheintal<br/>
//            Prof. Susanne Enderwitz, Katrin Gloggengiesser, René Broich
//             </ListItem>
//             <ListItem>Verschönerungsverein Bacharach<br/>
//             Fritz Stüber
//             </ListItem>
//             <ListItem>Dr. Walter Karbach</ListItem>
//         </List>
//         <Text >
//             Kontakt: <br/>enderwitz@kulturnetz.oberes-mittelrheintal
   
//         </Text>
//     </Box>
   
//    )


const Impressum =()=>{

    return(
        <motion.div
        initial={{opacity:0,y:0,paddingBottom:"40px"}}
        animate={{opacity:1,y:10}}
        transition={{duration:.6}}
        >
        <Box p="8">
             <MeinSEO title='Kontakt' description='Webseite zum Ausstellungsparcours. Jüdischens Leben im Oberen Mittelrheintal' keywords="Juden, Holocaust" />
             <Heading variant="MeH">Impressum</Heading>
         
        <Text variant='solid'>Veranstalter K.O.M., Bacharach, <br/>verantwortlich für Inhalt &amp; Datenschutz<br/><br/>
        Email: kontakt@kulturnetz-oberes-mittelrheintal.org
        </Text>
       <Text variant='solid'>
           Entwurf &amp; Programmierung der Webseite:<br/>
           K.O.M Katrin Gloggengiesser / René Broich
       </Text>
         
       <Text mt="4" px="0" fontWeight="600" variant="solid">Datenschutzbeauftragte: <br/>
           Prof. Susanne Enderwitz<br/>
           Email: enderwitz@kulturnetz-oberes-mittelrheintal.org
           </Text>
       
        <Link to="/datenschutz"> <Text _hover={{color:'blue.400'}} variant='solid'>
        &rarr; Unsere Datenschutzbestimmungen
        </Text></Link>
        </Box>
        </motion.div>

    )
} 
export default Impressum

